<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('warnings')" :isNewButton="checkPermission('warning_store')" :isColumns="true"
                    @new-button-click="showForm()" @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('warnings')" :isNewButton="checkPermission('warning_store')"
                    :isColumns="true" @new-button-click="showForm"
                    @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus"
            @filter="$refs.datatable.getRows()"
            @filterClear="$refs.datatable.filterClear()">
                <b-row>
                    <b-col sm="6" md="4" lg="3" xl="6">
                        <b-form-group :label="$t('subject')">
                            <b-form-input v-model="datatable.queryParams.filter.subject">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable ref="datatable"
                       :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       v-show="datatable.showTable"
                       :data-function="callService">
            </datatable>

            <CommonModal id="modal" size="xl" :onHideOnlyX="true"
                v-if="checkPermission('warning_store')">
                <template v-slot:CommonModalTitle>
                    {{ $t('new').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="formModalValidate">
                        <b-row>
                            <b-col cols="12">
                                {{$t('subject')}}
                                <hr>
                            </b-col>
                            <b-col cols="6" lg="6">
                                <ValidationProvider name="subject" rules="required" v-slot="{ valid, errors }">
                                    <b-input-group class="mb-1" prepend="TR">
                                        <b-form-input v-model="formData.subject" :state="errors[0] ? false : null" />
                                    </b-input-group>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="6" lg="6">
                                <ValidationProvider name="subject_en" rules="required" v-slot="{ valid, errors }">
                                    <b-input-group class="mb-1" prepend="EN">
                                        <b-form-input v-model="formData.subject_en" :state="errors[0] ? false : null" />
                                    </b-input-group>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                                </ValidationProvider>
                            </b-col>

                            <b-col cols="12">
                                {{$t('explanation')}}
                                <hr>
                            </b-col>
                            <b-col cols="6" lg="6">
                                <b-form-group>
                                    <ValidationProvider name="explanation"  rules="required" v-slot="{ valid, errors }">
                                        <ckeditor v-model="formData.explanation"/>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6" lg="6">
                                <b-form-group>
                                    <ValidationProvider name="explanation_en"  rules="required" v-slot="{ valid, errors }">
                                        <ckeditor v-model="formData.explanation_en"/>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <ValidationProvider name="start_date" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('start_date')">
                                        <select-date v-model="formData.start_date" :is-time="true" :state="errors[0] ? false : null" />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <ValidationProvider name="end_date" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('end_date')">
                                        <select-date v-model="formData.end_date" :is-time="true" :state="errors[0] ? false : null" />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <ValidationProvider name="status" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('status')">
                                        <status-selectbox v-model="formData.status" :state="errors[0] ? false : null" />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <b-col cols="12">
                                    <ValidationProvider name="faculty_code" rules="" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('faculty')">
                                            <faculty-selectbox v-model="formData.filter.faculty_code" :multiple="true"
                                                               :validate-error="errors[0]" />
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12">
                                    <ValidationProvider name="department_code" rules="" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('department')">
                                            <department-selectbox v-model="formData.filter.department_code" :multiple="true"
                                                                  :faculty_code="formData.filter.faculty_code" :validate-error="errors[0]" />
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12">
                                    <ValidationProvider name="program_code" rules="" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('program')">
                                            <program-selectbox v-model="formData.filter.program_code" :faculty_code="formData.filter.faculty_code"
                                                               :department_code="formData.filter.department_code" :multiple="true" :validate-error="errors[0]" />
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12">
                                    <ValidationProvider name="program_levels" rules="" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('level')">
                                            <parameter-selectbox v-model="formData.filter.program_levels" :multiple="true"
                                                                 :validate-error="errors[0]" code="program_levels"></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-col>
                            <b-col cols="6">
                                <b-col cols="12">
                                    <ValidationProvider name="student_number" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('student_numbers')">
                                            <lined-textarea v-model="formData.student_number"
                                                            :nowrap="false"
                                                            :disabled="false"
                                                            :styles="{ height: '15em', resize: 'both' }"
                                                            :validateError="errors[0]">
                                            </lined-textarea>
                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-col>
                        </b-row>
                        <div class="d-flex justify-content-center mt-2">
                            <b-button variant="primary" @click="save" :disabled="formLoading">
                                {{ $t('save') }}
                            </b-button>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Components
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import CommonModal from '@/components/elements/CommonModal';

// Services

import WarningsService from "@/services/WarningsService";
// Others
import qs from 'qs'
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox.vue";
import LinedTextarea from "@/components/elements/LinedTextarea.vue";
import AnnouncementsService from "@/services/AnnouncementsService";

export default {
    components: {
        LinedTextarea,
        StatusSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
    },
    metaInfo() {
        return {
            title: this.$t('warnings')
        }
    },
    data() {
        return {
            formData:{
                filter:{}
            },
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('edit'),
                                class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                permission: 'warning_update',
                                callback: (row) => {
                                    this.showForm(row.id)
                                }
                            },
                            {
                                text: this.$t('delete'),
                                permission: 'warning_delete',
                                class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                callback: (row) => {
                                    this.formDelete(row.id)
                                }
                            }
                        ]
                    },
                    {
                        label: this.toUpperCase('id'),
                        field: 'id',
                        hidden: true
                    },
                    {
                        label: this.toUpperCase('subject'),
                        field: 'subject',
                        hidden: false
                    },
                    {
                        label: this.toUpperCase('subject_en'),
                        field: 'subject_en',
                        hidden: false
                    },
                    {
                        label: this.toUpperCase('start_date'),
                        field: 'start_date',
                        hidden: false
                    },
                    {
                        label: this.toUpperCase('end_date'),
                        field: 'end_date',
                        hidden: false
                    },
                    {
                        label: this.toUpperCase('status'),
                        field: 'status',
                        hidden: false
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                }
            },
            formLoading: false
        }
    },
    mounted() {
    },
    methods: {
        callService(config) {
            return WarningsService.getAll(config);
        },
        showForm(id=0) {
            if(id>0){
                WarningsService.get(id)
                    .then(response => {
                        this.formData = {...response.data.data, filter:{}};
                        this.$bvModal.show('modal');
                    })
                    .catch(e => {
                        this.showErrors(e)
                    })
            }
            else {
                this.$bvModal.show('modal');
            }
        },

        async save() {

            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.formLoading = true;
                let formData = {...this.formData};
                if(formData.student_number){
                    formData.filter.student_number=formData.student_number.split(/\n/);
                }
                WarningsService.store(formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        if(typeof response.data.data.receiver_count != 'undefined'){
                            this.$toast.success(this.$t('n_receivers_created', {n: response.data.data.receiver_count}));
                        }
                        this.$bvModal.hide('modal');
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })

            }
        },

        FormSuccess() {
            this.$bvModal.hide('modal');
            this.$refs.datatable.getRows()
        },

        formDelete(id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        WarningsService.del(id)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.$refs.datatable.getRows();
                            })
                            .catch(error => {
                                this.$toast.error(this.$t('api.' + error.data.message));
                            });
                    }
                })
        },
    }
}
</script>

